import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { authController } from '../api/config/instance'
import { setNotification } from '../store/notifications/notificatonsAction'

// // Хук подключения к SSE
export const useConnectToSSEHub = () => {
  const dispatch = useDispatch()
  const SSESourceRef = useRef<EventSource | null>(null)

  const onNewNotificationListener = (event: MessageEvent) => {
    const { notificationChainId, payload } = JSON.parse(event.data)

    if (payload.downloadUrl !== null) {
      dispatch(
        setNotification({
          ...payload,
          notificationChainId: notificationChainId,
        })
      )
    }
  }

  const connect = async () => {
    await authController.authorizeService(
      `${import.meta.env.VITE_APP_SSE_HUB}api`
    )

    SSESourceRef.current = new EventSource(
      `${import.meta.env.VITE_APP_SSE_HUB}notification`,
      {
        withCredentials: true,
      }
    )

    SSESourceRef.current.addEventListener(
      'report_status',
      onNewNotificationListener
    )
  }

  useEffect(() => {
    connect().then()
    return () => {
      SSESourceRef.current?.removeEventListener(
        'report_status',
        onNewNotificationListener
      )
    }
  }, [])

  return { SSESourceRef }
}
