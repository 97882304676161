import React from 'react'

import { useTypedSelector } from '../../hooks/useTypedSelector'
import FilterCheckboxsModal from '../../../layouts/DrawerCustom/modals/FilterCheckboxModal/FilterCheckboxModal'
import ProductItemModal from '../../../features/Product/modals/ProductItemModal/ProductItemModal'
import OtherProductModal from './OtherProductModal/OtherProductModal'
import CopyOrderModal from '../../../features/NewHistory/modals/CopyOrderModal/CopyOrderModal'
import ExportOrdersInfoModal from '../../../features/NewHistory/modals/ExportOrdersInfoModal/ExportOrdersInfoModal'
import ExportOrderDetailsModal from '../../../features/NewHistory/modals/ExportOrderDetailsModal/ExportOrderDetailsModal'
import BonusModal from '../../../features/Sale/components/SaleCostBonuses/modals/BonusModal/BonusModal'
import SupplierBasketModal from '../../../features/Supplier/modals/SupplierBasketModal/SupplierBasketModal'
import OrderErrorModal from '../../../features/NewHistory/modals/OrderErrorModal/OrderErrorModal'
import OrderDeleteModal from '../../../features/NewHistory/modals/OrderDeleteModal/OrderDeleteModal'
import SaleDepartmentsModal from '../../../features/Sale/components/SaleDepartments/modals/SaleDepartmentsModal/SaleDepartmentsModal'
import StopListModal from './StopListModal/StopListModal'
import UnionDepartmentModal from '../../../features/Sale/components/SaleCostBonuses/modals/UnionDepartmentModal/UnionDepartmentModal'
import ResetModal from '../../../features/SettingsAutoOrder/components/SupplierSettings/modals/ResetModal/ResetModal'
import AutoOrderFieldsModal from '../../../features/AutoOrderFinal/components/AutoOrderProducts/modals/AutoOrderFieldsModal'
import AutoOrderDeleteModal from '../../../features/AutoOrderFinal/components/AutoOrderProducts/modals/AutoOrderDeleteModal'
import SupplierListModal from '../../../features/SettingsAutoOrder/components/SupplierList/modals/SupplierListModal/SupplierListModal'
import AutoOrderDeletePostionModal from '../../../features/AutoOrderFinal/components/AutoOrderProducts/modals/AutoOrderDeletePostionModal'
import ErrorDetailInfoModal from './ErrorDetailInfoModal/ErrorDetailInfoModal'

const RenderModal = () => {
  const { modalType } = useTypedSelector((state) => state.modal)
  let content
  switch (modalType) {
    case 'showSupplierBasketModal':
      content = <SupplierBasketModal />
      break
    case 'showSideFilters':
      content = <FilterCheckboxsModal />
      break
    case 'showProductItemModal':
      content = <ProductItemModal />
      break
    case 'showAlternativeProductModal':
      content = <OtherProductModal />
      break
    case 'showCopyOrderModal':
      content = <CopyOrderModal />
      break
    case 'showExportOrdersInfoModal':
      content = <ExportOrdersInfoModal />
      break
    case 'showExportOrderDetailsModal':
      content = <ExportOrderDetailsModal />
      break
    case 'showBonusModal':
      content = <BonusModal />
      break
    case 'showNewHistoryErrorModal':
      content = <OrderErrorModal />
      break
    case 'showNewHistoryDeleteModal':
      content = <OrderDeleteModal />
      break
    case 'showSaleDepartmentsModal':
      content = <SaleDepartmentsModal />
      break
    case 'showStopListModal':
      content = <StopListModal />
      break
    case 'showUnionDepartmentModal':
      content = <UnionDepartmentModal />
      break
    case 'showResetSupplierSettingsModal':
      content = <ResetModal />
      break
    case 'showAutoOrderFieldsModal':
      content = <AutoOrderFieldsModal />
      break
    case 'autoOrderDeleteModal':
      content = <AutoOrderDeleteModal />
      break
    case 'showSupplierListModal':
      content = <SupplierListModal />
      break
    case 'showDeletedPositionModal':
      content = <AutoOrderDeletePostionModal />
      break
    case 'showErrorDetailInfoModal':
      content = <ErrorDetailInfoModal />
      break
    default:
      return null
  }
  return <>{content}</>
}

export default RenderModal
