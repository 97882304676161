import React from 'react'
import { Button, Stack, Typography } from '@mui/material'
import { CloseModal } from '../../../../shared/types/types'
import { useTypedSelector } from '../../../../shared/hooks/useTypedSelector'
import { useExportController } from '../../controllers/useExportController'

const ExportOrderDetailsContent = ({ handleClose }: CloseModal) => {
  const { orderId } = useTypedSelector((state) => state.newHistory)
  const { notification } = useTypedSelector((state) => state.notifications)

  const { handleExportOrderDetails } = useExportController()
  const handleSubmit = async () => {
    const {
      data: { data },
    }: any = await handleExportOrderDetails(orderId)
    let url
    notification.forEach((item: any) => {
      if (item.notificationChainId === data.id) {
        url = item.downloadUrl
      }
    })

    handleClose()
  }

  return (
    <>
      <Typography
        sx={{
          color: 'secondary.main',
          fontSize: '1.5625rem',
          textAlign: 'center',
          fontWeight: '600',
          marginBottom: '30px',
        }}
      >
        Подтвердите выгрузку информации по заказу
      </Typography>
      <Stack flexDirection="row" alignItems="center" columnGap={'1rem'}>
        <Button onClick={handleSubmit} variant="contained" fullWidth>
          Подтвердить
        </Button>

        <Button onClick={handleClose} variant="outlined" fullWidth>
          Отменить
        </Button>
      </Stack>
    </>
  )
}

export default ExportOrderDetailsContent
