import React, { Suspense } from 'react'
import MainLayout from './layouts/MainLayout/MainLayout'
import PrivateRouter from './shared/routers/PrivateRouter'
import ThemeInjector from './shared/theme/ThemeInjector'
import SnackBarCustom from './shared/components/SnackBarCustom'
import './reset.css'
import './global.css'
import ErrorInterceptor from './shared/api/config/ErrorInterceptor'
import PreLoader from './shared/components/PreLoader/PreLoader'
import { useInitFetchController } from './shared/controllers/useInitFetchController'
import { useConnectToSSEHub } from './shared/hooks/useConnectToSSEHub'

const App = () => {
  useConnectToSSEHub()

  useInitFetchController()
  return (
    <ThemeInjector>
      <SnackBarCustom />
      <ErrorInterceptor />
      <Suspense fallback={<PreLoader />}>
        <MainLayout>
          <PrivateRouter />
        </MainLayout>
      </Suspense>
    </ThemeInjector>
  )
}

export default App
