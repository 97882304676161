import { useActions } from '../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'
import { useEffect, useRef } from 'react'
import { useObserver } from '../../../shared/hooks/useObserver'
import { NEW_HISTORY } from '../consts/newHistory'
import { LoadDirections } from '../../../shared/types/types'
import qs from 'qs'

type argsType = [number, number, any, boolean]

export const useNewHistoryController = () => {
  const { fetchNewHistoryProducts, setNewHistoryPage } = useActions()
  const {
    products,
    error,
    page,
    limit,
    filter,
    direction,
    enough,
    startSearch,
    order,
    orderBy,
    checkedItems,
    isShowNoted,
  } = useTypedSelector((state) => state.newHistory)

  const { loading } = useTypedSelector((state) => state.app)
  const inputRef = useRef<null | HTMLSpanElement[]>([])
  const savePageNum = useRef(page)
  const firstElement = useRef<HTMLDivElement>(null)
  const lastElement = useRef<HTMLDivElement>(null)

  const isFullLength = products.length >= NEW_HISTORY.MAX_PRODUCT_ITEMS

  useObserver(firstElement, enough && page > 1 && isFullLength, loading, () => {
    const isNotUpDirection = direction !== LoadDirections.UP && isFullLength
    setNewHistoryPage(
      isNotUpDirection
        ? page - Math.ceil(NEW_HISTORY.MAX_PRODUCT_ITEMS / limit)
        : page - 1
    )
  })

  useObserver(lastElement, enough, loading, () => {
    const isNotDownDirection = direction !== LoadDirections.DOWN && isFullLength
    setNewHistoryPage(
      isNotDownDirection
        ? page + Math.ceil(NEW_HISTORY.MAX_PRODUCT_ITEMS / limit)
        : page + 1
    )
  })

  useEffect(() => {
    if (isShowNoted && !checkedItems.length) return
    let newFilter = { ...filter }
    if (window.location.search) {
      let parsedParams: any = qs.parse(window.location.search.substring(1))
      newFilter = [...newFilter, ...parsedParams]
    }

    let args: argsType = [page, limit, newFilter, savePageNum.current < page]

    const fetch = async (...args: argsType) => fetchNewHistoryProducts(...args)

    fetch(...args).then(() => {
      if (!(isFullLength && inputRef.current !== null)) {
        savePageNum.current = page
        return
      }

      if (savePageNum.current < page)
        inputRef.current[products[products.length - 1].id]?.scrollIntoView() // скролл вниз

      if (savePageNum.current > page)
        inputRef.current[products[0].id]?.scrollIntoView() // скролл вверх
      savePageNum.current = page
    })
  }, [page, startSearch])

  return {
    error,
    firstElement,
    lastElement,
    inputRef,
    loading,
    products,
    order,
    orderBy,
  }
}
