import { OIDCAuthController } from '@proapteka/oidc-auth-controller'
import axios from 'axios'


const promarketApiUrl = import.meta.env.VITE_APP_API_URL

export const httpCore = axios.create({
  withCredentials: true,
  baseURL: promarketApiUrl,
  timeout: 10 * 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

export const authController = new OIDCAuthController({ Axios: httpCore })

export default { httpCore }
