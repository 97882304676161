import { ProductAction, ProductActionTypes } from './productReduxTypes'
import { Dispatch } from 'redux'
import {
  showOtherProductModal,
  showProductItemModal,
} from '../../../../shared/store/modal/modalAction'
import { ModalAction } from '../../../../shared/store/modal/modalsReduxTypes'
import { getAllCards } from '../../api/product'
import { Product } from '../../types/productTypes'
import {
  ApplicationAction,
  ApplicationActionTypes,
  SnackTypes,
} from '../../../../shared/store/application/applicationReduxTypes'

import noResultImage from '@images/search.png'

export const selectOtherProduct = (payload: Product) => {
  return (dispatch: Dispatch<ProductAction | ModalAction>) => {
    dispatch({
      type: ProductActionTypes.SELECT_PRODUCT,
      payload: payload,
    })
    dispatch(showOtherProductModal())
  }
}

export const selectProductModal = (payload: Product) => {
  return (dispatch: Dispatch<ProductAction | ModalAction>) => {
    dispatch({
      type: ProductActionTypes.SELECT_PRODUCT,
      payload: payload,
    })
    dispatch(showProductItemModal())
  }
}

export const fetchAllProducts = (
  department: number | undefined,
  page = 1,
  limit = 20,
  filter = {},
  loadFlag = true
) => {
  return async (dispatch: Dispatch<ProductAction | ApplicationAction>) => {
    dispatch({ type: ApplicationActionTypes.LOADING_ENABLE })

    try {
      const { data } = await getAllCards(department, page, limit, filter)

      const content = data.content
      const isEmpty = content.data.length === 0
      if (isEmpty) {
        dispatch({
          type: ProductActionTypes.FETCH_PRODUCTS_ERROR,
          payload: { message: 'Поиск не дал результатов', img: noResultImage },
        })
        return
      }
      const isEnough = content.data.length >= limit
      dispatch({
        type: ProductActionTypes.FETCH_PRODUCTS_ENOUGH,
        payload: isEnough,
      })
      dispatch({
        type: ProductActionTypes.FETCH_PRODUCTS_SUCCESS,
        payload: { content, loadFlag },
      })
    } catch (e) {
      dispatch({
        type: ProductActionTypes.FETCH_PRODUCTS_ERROR,
        payload: {
          message: 'У грузополучателя нет доступа к прайс-листам',
          img: noResultImage,
        },
      })
    } finally {
      dispatch({ type: ApplicationActionTypes.LOADING_DISABLE })
    }
  }
}

export function setProductPage(page: number): ProductAction {
  if (page === 0) page = 1
  return { type: ProductActionTypes.SET_PRODUCT_PAGE, payload: page }
}

export function setFilterProducts() {
  return { type: ProductActionTypes.SET_FILTER_PRODUCTS }
}

export function setFilterCustom(payload: Object) {
  return { type: ProductActionTypes.SET_FILTER_CUSTOM, payload }
}

export function initProductEditMode(payload: boolean) {
  return { type: ProductActionTypes.INIT_EDIT_MODE, payload }
}

export function resetFilterProducts() {
  return { type: ProductActionTypes.RESET_FILTER_PRODUCTS }
}

export const setLimitPage = (limit: number) => {
  localStorage.setItem('pageLimit', String(limit))
  return { type: ProductActionTypes.SET_PRODUCT_LIMIT, payload: limit }
}
