import { ChangeEvent } from 'react'
import { Dispatch } from 'redux'
import debounce from 'lodash/debounce'
import { ApplicationAction } from '../../../../../../shared/store/application/applicationReduxTypes'
import {
  ISaleComplexPayload,
  SaleComplexAction,
  SaleComplexActionTypes,
} from './saleComplexReduxTypes'

import noResultImage from '@images/search.png'
import {
  deleteStopListItem,
  getSaleComplexItems,
  getSaleComplexOptions,
  postBonusInPriceEnable,
  putChangeDepartmentsNeeds,
  putSaleComplexOptions,
  putStopListItem,
} from '../../../../api/sale'
import { AppStateType } from '../../../../../../shared/store'
import { updateCostBonusesAction } from '../../../SaleCostBonuses/model/saleCostBonuses/saleCostBonusesAction'
import { updateSaleDepartmentsAction } from '../../../SaleDepartments/model/saleDepartments/saleDepartmentsAction'
import { SaleCostBonusesAction } from '../../../SaleCostBonuses/model/saleCostBonuses/saleCostBonusesReduxTypes'
import { SaleDepartmentsAction } from '../../../SaleDepartments/model/saleDepartments/saleDepartmentsReduxTypes'
import {
  IDepartmentsNeedsParams,
  IStopListParams,
} from '../../../../../../shared/types/types'

const debouncedFn = debounce((model) => putSaleComplexOptions(model), 800)

export const fetchSaleComplexProductsAction = (
  page = 1,
  limit = 30,
  filter = {},
  loadFlag = true
) => {
  return async (dispatch: Dispatch<SaleComplexAction | ApplicationAction>) => {
    try {
      dispatch({ type: SaleComplexActionTypes.LOADING_ENABLE })
      const { data } = await getSaleComplexItems(page, limit, filter)
      const content = data.content
      const isEmpty = content.productsStockAndSales.length === 0 && page === 1

      if (isEmpty) {
        dispatch({
          type: SaleComplexActionTypes.FETCH_PRODUCTS_ERROR,
          payload: { message: 'Поиск не дал результатов', img: noResultImage },
        })
        return
      }
      const isEnough = content.productsStockAndSales.length >= limit
      dispatch({
        type: SaleComplexActionTypes.FETCH_PRODUCTS_ENOUGH,
        payload: isEnough,
      })
      dispatch({
        type: SaleComplexActionTypes.FETCH_PRODUCTS_SUCCESS,
        payload: { content, loadFlag },
      })
    } catch (e) {
      dispatch({
        type: SaleComplexActionTypes.FETCH_PRODUCTS_ERROR,
        payload: { message: 'Поиск завершился ошибкой', img: noResultImage },
      })
    } finally {
      dispatch({ type: SaleComplexActionTypes.LOADING_DISABLE })
    }
  }
}

export function setSaleComplexPage(page: number): SaleComplexAction {
  if (page === 0) page = 1
  return { type: SaleComplexActionTypes.SET_PRODUCT_PAGE, payload: page }
}

export function setSaleComplexFilterProducts(
  payload: Object
): SaleComplexAction {
  return {
    type: SaleComplexActionTypes.SET_FILTER_PRODUCTS,
    payload,
  }
}

export function resetSaleComplexFilterProducts(): SaleComplexAction {
  return { type: SaleComplexActionTypes.RESET_FILTER_PRODUCTS }
}

export function resetSaleComplexState(): SaleComplexAction {
  return { type: SaleComplexActionTypes.RESET_SALE_COMPLEX_STATE }
}

export function setSaleComplexSort(
  payload: ISaleComplexPayload
): SaleComplexAction {
  return { type: SaleComplexActionTypes.SET_PRODUCT_SORT, payload }
}

export const addStopListItemAction = (
  params: IStopListParams
): SaleComplexAction => ({
  type: SaleComplexActionTypes.ADD_SALE_COMPLEX_STOP_LIST_ITEM,
  payload: params,
})

export const deleteSaleComplexStopListItemAction = (
  params: IStopListParams
): SaleComplexAction => ({
  type: SaleComplexActionTypes.DELETE_SALE_COMPLEX_STOP_LIST_ITEM,
  payload: params,
})

export const deleteAllSaleStopListItemsAction = (): SaleComplexAction => ({
  type: SaleComplexActionTypes.DELETE_ALL_SALE_COMPLEX_STOP_LIST_ITEMS,
})

const debouncedPutStopListItem = debounce(
  async (
    params,
    dispatch: Dispatch<
      | SaleComplexAction
      | SaleCostBonusesAction
      | SaleDepartmentsAction
      | ApplicationAction
    >
  ) => {
    try {
      let { data } = await putStopListItem(params)
      dispatch(updateCostBonusesAction(data.content.uiChanges.bonuses))
      dispatch(updateSaleDepartmentsAction(data.content.uiChanges.departments))
      dispatch(addStopListItemAction(params))
    } catch (e) {
      // dispatch(
      //   showSnack({
      //     typeSnack: SnackTypes.ERROR,
      //     messageSnack: 'Превышен лимит стоп-листа',
      //   })
      // )
    }
  },
  200
)

const debouncedDeleteStopListItem = debounce(
  async (
    params,
    dispatch: Dispatch<
      | SaleComplexAction
      | SaleCostBonusesAction
      | SaleDepartmentsAction
      | ApplicationAction
    >
  ) => {
    try {
      let { data } = await deleteStopListItem(params)
      dispatch(updateCostBonusesAction(data.content.uiChanges.bonuses))
      dispatch(updateSaleDepartmentsAction(data.content.uiChanges.departments))
      dispatch(deleteSaleComplexStopListItemAction(params))
    } catch (e) {
      // dispatch(
      //   showSnack({
      //     typeSnack: SnackTypes.ERROR,
      //     // messageSnack: 'Превышен лимит',
      //     messageSnack: 'Превышен лимит стоп-листа',
      //   })
      // )
    }
  },
  200
)

export const stopListHandlerAction = (
  event: ChangeEvent<HTMLInputElement>,
  params: IStopListParams
) => {
  return async (
    dispatch: Dispatch<
      | SaleComplexAction
      | SaleCostBonusesAction
      | SaleDepartmentsAction
      | ApplicationAction
    >
  ) => {
    const isChecked = event.target.checked

    isChecked
      ? debouncedPutStopListItem(params, dispatch)
      : debouncedDeleteStopListItem(params, dispatch)
  }
}

export const enableSaleComplexBlockAction = (): SaleComplexAction => ({
  type: SaleComplexActionTypes.ENABLE_SALE_COMPLEX_BLOCK_TABLE,
})

export const enableSaleComplexBonusPrice = () => {
  return async (dispatch: Dispatch<SaleComplexAction | ApplicationAction>) => {
    try {
      await postBonusInPriceEnable()
      dispatch(enableSaleComplexBlockAction())
    } catch (e) {
      // dispatch(
      //   showSnack({
      //     typeSnack: SnackTypes.ERROR,
      //     messageSnack: 'Ошибка получения параметров',
      //   })
      // )
    }
  }
}

export const fetchSaleComplexOptionsAction = () => {
  return async (dispatch: Dispatch<SaleComplexAction>) => {
    try {
      let {
        data: { content },
      } = await getSaleComplexOptions()
      dispatch({
        type: SaleComplexActionTypes.SAVE_SALE_COMPLEX_OPTIONS,
        payload: content,
      })
    } catch (e) {
      // alert('Ошибка получения грузополучателя')
    }
  }
}

export const changeSaleComplexPeriodAction = (
  e: ChangeEvent<HTMLInputElement>
) => {
  return async (
    dispatch: Dispatch<SaleComplexAction>,
    getState: () => AppStateType
  ) => {
    try {
      const payload = e.target.value
      dispatch({
        type: SaleComplexActionTypes.SAVE_SALE_COMPLEX_OPTION_PERIOD,
        payload,
      })
      const state = getState()
      const model = state.saleComplex.options
      putSaleComplexOptions(model)
    } catch (e) {
      // alert('Ошибка сохранения')
    }
  }
}

export const fetchOnlyHighMarginProducts = (payload: boolean) => {
  return async (
    dispatch: Dispatch<SaleComplexAction>,
    getState: () => AppStateType
  ) => {
    try {
      dispatch({
        type: SaleComplexActionTypes.FETCH_HIGH_MARGIN,
        payload,
      })
      const state = getState()
      const model = state.saleComplex.options
      putSaleComplexOptions(model)
    } catch (e) {}
  }
}

export const setShelfLifePercentAction = (val: string) => {
  return async (
    dispatch: Dispatch<SaleComplexAction>,
    getState: () => AppStateType
  ) => {
    try {
      dispatch({
        type: SaleComplexActionTypes.SAVE_SALE_COMPLEX_TEXT_SHELF_LIFE_PERCENT,
        payload: val,
      })
      const state = getState()
      const model = state.saleComplex.options
      await debouncedFn(model)
    } catch (e) {
      // alert('Ошибка сохранения')
    }
  }
}

export const setShelfLifeAction = (val: string) => {
  return async (
    dispatch: Dispatch<SaleComplexAction>,
    getState: () => AppStateType
  ) => {
    try {
      dispatch({
        type: SaleComplexActionTypes.SAVE_SALE_COMPLEX_TEXT_SHELF_LIFE,
        payload: val,
      })
      const state = getState()
      const model = state.saleComplex.options
      debouncedFn(model)
    } catch (e) {
      // alert('Ошибка сохранения')
    }
  }
}

const debouncedQuantityForOrder = debounce(
  async (
    params,
    dispatch: Dispatch<
      | SaleComplexAction
      | SaleCostBonusesAction
      | SaleDepartmentsAction
      | ApplicationAction
    >
  ) => {
    try {
      let { data } = await putChangeDepartmentsNeeds(params) // debounce
      dispatch({
        type: SaleComplexActionTypes.SAVE_SALE_COMPLEX_QUANTITY_FOR_ORDER,
        payload: params,
      })
      dispatch(updateCostBonusesAction(data.content.uiChanges.bonuses))
      dispatch(updateSaleDepartmentsAction(data.content.uiChanges.departments))
    } catch (e) {
      // dispatch(
      //   showSnack({
      //     typeSnack: SnackTypes.ERROR,
      //     messageSnack: 'Превышен лимит стоп-листа',
      //     // messageSnack: 'Превышен лимит',
      //   })
      // )
    }
  },
  200
)

export const setQuantityForOrderAction =
  (params: IDepartmentsNeedsParams) =>
  (
    dispatch: Dispatch<
      | SaleComplexAction
      | SaleCostBonusesAction
      | SaleDepartmentsAction
      | ApplicationAction
    >
  ) =>
    debouncedQuantityForOrder(params, dispatch)

export const changeSaleComplexCheckBoxsAction = (
  e: ChangeEvent<HTMLInputElement>
) => {
  return async (
    dispatch: Dispatch<SaleComplexAction>,
    getState: () => AppStateType
  ) => {
    try {
      const payload = e.target.checked
      const name = e.target.name

      switch (name) {
        case 'considerAvailabilityByQuantity':
          dispatch({
            type: SaleComplexActionTypes.SAVE_SALE_COMPLEX_OPTION_AVAILABILITY,
            payload,
          })
          break
        case 'remainingShelfLifeInclude':
          dispatch({
            type: SaleComplexActionTypes.SAVE_SALE_COMPLEX_OPTION_SHELF_LIFE,
            payload,
          })
          break
        case 'remainingShelfLifePercentInclude':
          dispatch({
            type: SaleComplexActionTypes.SAVE_SALE_COMPLEX_OPTION_SHELF_LIFE_PERCENT,
            payload,
          })
          break
        case 'onlyWithPriceGain':
          dispatch({
            type: SaleComplexActionTypes.SAVE_SALE_COMPLEX_OPTION_PRICE_GAIN,
            payload,
          })
          break
      }
      const state = getState()
      const model = state.saleComplex.options
      await putSaleComplexOptions(model)
    } catch (e) {
      // alert('Ошибка сохранения')
    }
  }
}
