import { useState } from 'react'
import dayjs from 'dayjs'
import { getExportOrderDetails, getExportOrdersInfo } from '../api/newHistory'
import { IDateRange } from '../../../shared/types/types'
import { IOrderStatusProps } from '../types/newHistoryTypes'

export const useExportController = () => {
  const [isLoadingExport, setIsLoadingExport] = useState(false)
  const handleExportOrderDetails = async (id: number | null) => {
    try {
      setIsLoadingExport(true)
      return await getExportOrderDetails(id)
    } catch (error) {
    } finally {
      setIsLoadingExport(false)
    }
  }

  const handleExportOrdersInfo = async (
    onlyMyOrders: boolean,
    statuses: IOrderStatusProps[] | null,
    dateRange: IDateRange | null,
    departmentIds: number[] | null,
    supplierIds: number[] | null
  ) => {
    try {
      let dateFrom =
        dayjs(dateRange?.startDate).format('YYYY-MM-DDTHH:mm:ss') || null
      if (dateFrom === 'Invalid Date') dateFrom = null
      let dateTo =
        dayjs(dateRange?.endDate).format('YYYY-MM-DDTHH:mm:ss') || null
      if (dateTo === 'Invalid Date') dateTo = null
      const statusIds = statuses ? statuses.map((status) => status.id) : null
      setIsLoadingExport(true)
      return await getExportOrdersInfo(
        onlyMyOrders,
        statusIds,
        dateFrom,
        dateTo,
        departmentIds,
        supplierIds
      )
    } catch (error) {
    } finally {
      setIsLoadingExport(false)
    }
  }

  return { handleExportOrderDetails, handleExportOrdersInfo }
}
