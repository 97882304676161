import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IOrderStatusProps } from '../../../features/NewHistory/types/newHistoryTypes'

export default function StatusAutoComplete({
  value,
  fieldWidth,
  variant,
  handleAutoCompleteFields,
}: {
  // value?: IOrderStatusProps | null
  value?: any
  fieldWidth?: string
  variant?: string
  handleAutoCompleteFields: (newValue: any) => void
}) {
  const { t } = useTranslation()

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />

  const isMultiple = variant === 'multiple'

  return isMultiple ? (
    <Autocomplete
      disableCloseOnSelect={isMultiple}
      disablePortal
      value={value}
      multiple={isMultiple}
      size="small"
      sx={{
        width: `${fieldWidth || '230px'}`,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#B5C3DC',
        },
        '& .MuiInputLabel-formControl': {
          fontSize: '0.875rem',
          mt: '2px',
        },
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 5 }}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
            checked={selected}
          />
          <Typography style={{ fontSize: '12px' }}>{option.alias}</Typography>
        </li>
      )}
      onChange={(event: any, newValue: any) => {
        handleAutoCompleteFields(newValue)
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.alias}
      options={[
        { id: 1, alias: t('dialog.new') },
        { id: 2, alias: t('dialog.completed') },
        { id: 3, alias: t('dialog.sended') },
        { id: 4, alias: t('dialog.parted_approved') },
        { id: 5, alias: t('dialog.approved') },
        { id: 6, alias: t('dialog.refused') },
      ]}
      renderInput={(params) => <TextField {...params} label="Статус" />}
    />
  ) : (
    <Autocomplete
      disablePortal
      value={value}
      size="small"
      sx={{
        width: `${fieldWidth || '230px'}`,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#B5C3DC',
        },
        '& .MuiInputLabel-formControl': {
          fontSize: '0.875rem',
          mt: '2px',
        },
      }}
      onChange={(event: any, newValue: any) => {
        handleAutoCompleteFields(newValue)
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.alias}
      options={[
        { id: 1, alias: t('dialog.new') },
        { id: 2, alias: t('dialog.completed') },
        { id: 3, alias: t('dialog.sended') },
        { id: 4, alias: t('dialog.parted_approved') },
        { id: 5, alias: t('dialog.approved') },
        { id: 6, alias: t('dialog.refused') },
      ]}
      renderInput={(params) => <TextField {...params} label="Статус" />}
    />
  )
}
