import { ModalAction, ModalActionTypes, ModalState } from './modalsReduxTypes'

const initialState: ModalState = {
  active: false,
  modalType: null,
}

export const modalReducer = (
  state = initialState,
  action: ModalAction
): ModalState => {
  switch (action.type) {
    case ModalActionTypes.BASKET_MODAL:
      return { active: true, modalType: 'basket' }
    case ModalActionTypes.SUPPLIER_BASKET_MODAL:
      return { active: true, modalType: 'showSupplierBasketModal' }
    case ModalActionTypes.SIDE_FILTERS_MODAL:
      return { active: true, modalType: 'showSideFilters' }
    case ModalActionTypes.PRODUCT_ITEM_MODAL:
      return { active: true, modalType: 'showProductItemModal' }
    case ModalActionTypes.ALTERNATIVE_PRODUCT_MODAL:
      return { active: true, modalType: 'showAlternativeProductModal' }
    case ModalActionTypes.COPY_ORDER_MODAL:
      return { active: true, modalType: 'showCopyOrderModal' }
    case ModalActionTypes.EXPORT_ORDERS_INFO_MODAL:
      return { active: true, modalType: 'showExportOrdersInfoModal' }
    case ModalActionTypes.EXPORT_ORDER_DETAILS_MODAL:
      return { active: true, modalType: 'showExportOrderDetailsModal' }
    case ModalActionTypes.BONUS_MODAL:
      return { active: true, modalType: 'showBonusModal' }
    case ModalActionTypes.NEW_HISTORY_ERROR_MODAL:
      return { active: true, modalType: 'showNewHistoryErrorModal' }
    case ModalActionTypes.NEW_HISTORY_DELETE_MODAL:
      return { active: true, modalType: 'showNewHistoryDeleteModal' }
    case ModalActionTypes.SALE_DEPARTMENTS_MODAL:
      return { active: true, modalType: 'showSaleDepartmentsModal' }
    case ModalActionTypes.STOP_LIST_MODAL:
      return { active: true, modalType: 'showStopListModal' }
    case ModalActionTypes.UNION_DEPARTMENT_MODAL:
      return { active: true, modalType: 'showUnionDepartmentModal' }
    case ModalActionTypes.MODAL_DISABLE:
      return { active: false, modalType: null }
    case ModalActionTypes.RESET_SUPPLIER_SETTINGS_MODAL:
      return { active: true, modalType: 'showResetSupplierSettingsModal' }
    case ModalActionTypes.AUTO_ORDER_SET_FIELDS_MODAL:
      return { active: true, modalType: 'showAutoOrderFieldsModal' }
    case ModalActionTypes.AUTO_ORDER_DELETE_MODAL:
      return { active: true, modalType: 'autoOrderDeleteModal' }
    case ModalActionTypes.SUPPLIER_LIST_MODAL:
      return { active: true, modalType: 'showSupplierListModal' }
    case ModalActionTypes.DELETED_POSITION_MODAL:
      return { active: true, modalType: 'showDeletedPositionModal' }
    case ModalActionTypes.APPLICATION_ERROR_DETAIL_INFO_MODAL:
      return { active: true, modalType: 'showErrorDetailInfoModal' }
    default:
      return state
  }
}
