/// <reference types="vite-plugin-svgr/client" />
import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'
import MoleculeIcon from '@images/Molecule.svg?react'
import { Tooltip } from '@mui/material'

const SvgHospital = ({
  renderCondition = false,
  initialSizes = {
    width: '20px',
    height: '20px',
  },
}: {
  renderCondition?: boolean
  initialSizes?: {
    [key: string]: string
  }
}) => {
  if (!renderCondition) return null
  return (
    <Tooltip title="МИБП">
      <SvgIcon
        viewBox="0 0 20 20"
        style={{
          ...initialSizes,
        }}
      >
        <MoleculeIcon />
      </SvgIcon>
    </Tooltip>
  )
}

export default SvgHospital
