import { ChangeEvent } from 'react'
import { IOrderHistoryItems } from '../../features/NewHistory/types/newHistoryTypes'
import get from 'lodash/get'
import qs from 'qs'
import { IProduct } from '../../features/Product/types/productTypes'
import { IBasketVal } from '../../features/Basket/types/basketTypes'

export const onlyNumbers = (e: ChangeEvent<HTMLInputElement>) => {
  e.target.value = e.target.value.replace(/[^0-9]/g, '')
}

export const transformRequest = (v: unknown, sortedId = 'id') => {
  if (Array.isArray(v)) {
    return v
      .map((i) => {
        if (i[sortedId]) {
          return i[sortedId]
        }
        return i
      })
      .filter((i) => i !== -1)
  }
  return v
}

export const onlyNotEmpty = (params: any) => {
  return Object.fromEntries(
    Object.entries(params).filter(([_, v]) => {
      if (Array.isArray(v)) {
        return v.length !== 0 && v[0] !== -1
      }
      return v !== null && v !== '' && v !== undefined
    })
  )
}

export const getCardPrimaryKey = (card: IProduct, departmentId?: number) => {
  return (
    '' +
    (departmentId || '') +
    (card.supplierGoodId || '') +
    (card.priceListTypeId || '') +
    (card.storeCode || '') +
    (card.payerCode || '')
  )
}

export const getBasketPrimaryKey = (item: IBasketVal) => {
  return (
    '' +
    (item.departmentId || '') +
    (item.supplierGoodId || '') +
    (item.priceListTypeId || '') +
    (item.storeCode || '') +
    (item.payerCode || '')
  )
}

export const qsStringify = (params: Object) => {
  return qs.stringify(onlyNotEmpty(params))
}

export const qsGetProperty = (prop: string) => {
  const subUrl = window.location.search.substring(1)
  return qs.parse(subUrl)?.[prop] || ''
}

export const checkMultiplicity = (mainField: number, field: number) =>
  mainField % field
export const checkMinCount = (mainField: number, field: number) =>
  mainField < field
export const checkMaxCount = (mainField: number, field: number) =>
  mainField > field

export const textErrorMultiplicity = (i: IOrderHistoryItems) =>
  !!checkMultiplicity(i.amount, i.product.boxQuantity) ? 'Кратность' : null
export const textErrorMinimalQuantity = (i: IOrderHistoryItems) =>
  checkMinCount(i.amount, Number(i.product.minimalQuantity))
    ? 'Мин.кол-во'
    : null
export const textErrorQuantity = (i: IOrderHistoryItems) =>
  checkMaxCount(i.amount, Number(i.product.quantity)) ? 'Доступность' : null

export const getNext = (key: any) => {
  let next
  switch (key) {
    case '':
      next = 1
      break
    case 1:
      next = -1
      break
    default:
      next = ''
  }
  return next
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const a1 = get(a, orderBy)
  const b1 = get(b, orderBy)
  if (b1 < a1) {
    return -1
  }
  if (b1 > a1) {
    return 1
  }
  return 0
}

export type Order = 'asc' | 'desc'

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}
