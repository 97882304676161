/// <reference types="vite-plugin-svgr/client" />
import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'
import PlusIcon from '@images/Plus +.svg?react'
import { Tooltip } from '@mui/material'

const SvgPlus = ({
  renderCondition = false,
}: {
  renderCondition?: boolean
}) => {
  if (!renderCondition) return null
  return (
    <Tooltip title="Мое здоровье">
      <SvgIcon
        viewBox="0 0 20 20"
        style={{
          width: '20px',
          height: '20px',
        }}
      >
        <PlusIcon />
      </SvgIcon>
    </Tooltip>
  )
}

export default SvgPlus
