/// <reference types="vite-plugin-svgr/client" />
import React from 'react'

// mui imports
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'

// project imports
import DepartmentsWithNeededProductsRow from './DepartmentsWithNeededProductsRow'

// assets
import SvgIcon from '@mui/material/SvgIcon'
import StopListlIcon from '@images/StopSign.svg?react'

const DepartmentsWithNeededProducts = ({
  departmentsWithNeededProducts,
  open,
}: {
  departmentsWithNeededProducts: any
  open: boolean
}) => {
  return (
    <>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ margin: 1 }}>
          <Typography
            paddingLeft={5}
            gutterBottom
            fontWeight={500}
            fontSize={'0.725rem'}
            component="div"
          >
            Подразделения
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow
                sx={{
                  '& td,th': {
                    whiteSpace: 'pre-wrap',
                    fontSize: '0.6rem',
                    color: 'primary.main',
                    fontWeight: '700',
                    textTransform: 'uppercase',
                    borderBottom: 0,
                  },
                }}
              >
                <TableCell sx={{ padding: 'unset' }}>
                  <SvgIcon
                    viewBox="0 0 18 18"
                    style={{
                      marginTop: '15px',
                    }}
                  >
                    <StopListlIcon />
                  </SvgIcon>
                </TableCell>
                <TableCell align="left" sx={{ padding: '6px 8px' }}>
                  Наименование
                </TableCell>
                <TableCell align="center">Количество</TableCell>
                <TableCell align="center">Мин кол.</TableCell>
                <TableCell align="center">Кратность</TableCell>
                <TableCell align="center">Цена проаптека</TableCell>
                <TableCell align="center">Сумма проаптека</TableCell>
                <TableCell align="center">ЖНВЛП</TableCell>
                <TableCell align="center">Годен до</TableCell>
                <TableCell align="center">% остат.срока год.</TableCell>
                <TableCell align="center">Мцк</TableCell>
                <TableCell
                  sx={{
                    padding: '0.5rem 0 0 0',
                  }}
                  align="center"
                >
                  Наименование конкурента
                </TableCell>
                <TableCell align="center">Дельта</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {departmentsWithNeededProducts.map((product: any) => (
                <React.Fragment key={product.departmentId}>
                  <DepartmentsWithNeededProductsRow product={product} />
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Collapse>
    </>
  )
}

export default DepartmentsWithNeededProducts
