import { TableCellProps } from '@mui/material'

export interface IOptionsProps {
  id: number
  name: string
  isActive?: boolean
}

export interface ErrorInfo {
  message: string
  img: string | undefined
}

export enum LoadDirections {
  DOWN = 'DOWN',
  UP = 'UP',
}

export interface HeaderProps {
  handleDrawerToggle: () => void
}

export interface CloseModal {
  handleClose: () => void
}

export interface IAutoCompleteProps {
  width?: string
  customId?: string
  customName?: string
  customPopover?: boolean
  limitTags?: number
  value?: IOptionsProps[] | null
  variant: 'multiple' | 'standard'
  handleAutoCompleteFields: (newValue: any) => void
  handleDepartmentValue?: (newValue: any) => void
  isCheckBoxType: boolean
}

export interface IMarketingStatuses {
  isBad: boolean
  isBenefit: boolean
  isFirstHundredBonus: boolean
  isImmunoBiological: boolean
  isJvnls: boolean
  isMyHealth: boolean
  isOa: boolean
  isPrPkkn: boolean
  isProApt: boolean
  isReceipt: boolean
}

export type TableAlignType = TableCellProps['align']

export interface IDepartmentsNeedsParams {
  depId: number
  productEscode: string
  amount: number
  id: string
}

export interface IStopListParams {
  depId: number
  productEscode: string
}

export interface IDateRange {
  startDate: Date | null
  endDate: Date | null
}
