import { useMemo, useState } from 'react'

// redux
import { useTypedSelector } from '../hooks/useTypedSelector'
import { useActions } from '../hooks/useActions'

// api
import {
  deleteBasketArray,
  postBasket,
  putBasketItem,
} from '../../features/Basket/api/basket'
import { postAddItemOrders } from '../api/order'

// types
import { IProduct } from '../../features/Product/types/productTypes'
import {
  ModeList,
  SnackTypes,
} from '../store/application/applicationReduxTypes'

// service
import { getInitialProduct } from '../services/basketService'
import { getCardPrimaryKey } from '../services/commonService'
import debounce from 'lodash/debounce'

export const useBuyController = (card: IProduct) => {
  const defaultDepartment = useTypedSelector(
    (state) => state.app.defaultDepartment
  )
  const appMode = useTypedSelector((state) => state.app.appMode)
  const basketRecords = useTypedSelector((state) => state.basket.basketRecords)

  const primaryKey = getCardPrimaryKey(card, defaultDepartment?.id)

  const {
    showSnack,
    selectOtherProduct,
    storeBasketRecordsInCloud,
    fetchBasketRecords,
    deleteBasketRecord,
  } = useActions()

  const [itemAmount, setItemAmount] = useState(0)

  const changeItemAmount = useMemo(
    () =>
      debounce((val: number, basketId?: number) => {
        setItemAmount(val)
        handleBuy(val, basketId)
      }, 800),
    [basketRecords, appMode, defaultDepartment]
  )

  const handleBuy = (itemAmount: number, basketId?: number) => {
    const isEditMode = appMode.mode === ModeList.EDIT
    if (isEditMode) {
      postAddItemOrders(
        itemAmount,
        card.id,
        Number(appMode.data),
        getInitialProduct(card)
      )
        .then(() => {
          showSnack({
            typeSnack: SnackTypes.SUCCESS,
            messageSnack: 'Позиция добавлена',
          })
        })
        .catch(
          () => {}
          // showSnack({
          //   typeSnack: SnackTypes.ERROR,
          //   messageSnack: 'Ошибка запроса',
          // })
        )
    } else if (defaultDepartment !== null) {
      const departmentId = Number(defaultDepartment.id)
      const basketItemId = basketId

      if (basketItemId) {
        if (itemAmount === 0) {
          deleteBasketArray([basketItemId]).then(() => {
            deleteBasketRecord(String(primaryKey))
            fetchBasketRecords()
          })
          return
        }

        putBasketItem(basketItemId, itemAmount).then(
          ({
            data: {
              content: { id: basketId, amount },
            },
          }) => {
            storeBasketRecordsInCloud({ [primaryKey]: { basketId, amount } })
            fetchBasketRecords()
          }
        )
        return
      }
      postBasket([
        {
          supplierId: card.supplierId,
          departmentId,
          priceListTypeId: card.priceListTypeId,
          supplierGoodId: card.supplierGoodId,
          storeCode: card.storeCode,
          payerCode: card.payerCode,
          amount: itemAmount,
          initialProduct: getInitialProduct(card),
        },
      ])
        .then(({ data: { content } }) => {
          const basketId = content.items[0].id
          const amount = content.items[0].amount
          storeBasketRecordsInCloud({ [primaryKey]: { basketId, amount } })
          fetchBasketRecords()
        })
        .catch(
          () => {}
          // showSnack({
          //   typeSnack: SnackTypes.ERROR,
          //   messageSnack: 'Выставите кол-во',
          // })
        )
    }
  }

  return {
    changeItemAmount,
    handleBuy,
    defaultDepartment,
    selectOtherProduct,
    itemAmount,
  }
}
