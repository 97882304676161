import {
  NewHistoryAction,
  NewHistoryActionTypes,
  NewHistoryState,
} from './newHistoryReduxTypes'
import { HISTORY_STATUS, NEW_HISTORY } from '../../consts/newHistory'
import { transformHistory } from '../../../../shared/services/historyService'
import { ErrorInfo, LoadDirections } from '../../../../shared/types/types'

const initialFilter = {
  productIdFilter: '',
  orderIdRangeFilter: '',
  startDate: null,
  endDate: null,
  productNameFilter: '',
  supplierIdFilter: [],
  departmentIdFilter: [],
  statusIdFilter: [],
  onlyMyOrders: true,
  order: '-createdat',
}

const initialState: NewHistoryState = {
  enough: false,
  direction: LoadDirections.DOWN,
  loading: false,
  error: {} as ErrorInfo,
  page: 1,
  limit: 30,
  total: 30,
  filter: initialFilter,
  products: [],
  orderId: null,
  startSearch: false,
  checkedItems: [],
  isCheckedAll: false,
  isShowNoted: false,
  isShowErrors: false,
  orderBy: 'createdAt',
  order: 'desc',
  deletedItem: null,
}

export const newHistoryReducer = (
  state = initialState,
  action: NewHistoryAction
): NewHistoryState => {
  switch (action.type) {
    case NewHistoryActionTypes.START_NEW_HISTORY_SEARCH:
      return {
        ...state,
        products: [],
        page: 1,
        enough: false,
        startSearch: !state.startSearch,
      }
    case NewHistoryActionTypes.SET_FILTER_PRODUCTS:
      return {
        ...state,
        filter: { ...state.filter, ...action.payload },
      }
    case NewHistoryActionTypes.RESET_FILTER_PRODUCTS:
      return {
        ...state,
        filter: initialFilter,
        products: [],
        page: 1,
        startSearch: !state.startSearch,
      }
    case NewHistoryActionTypes.FETCH_PRODUCTS_SUCCESS:
      const { content, loadFlag } = action.payload

      const resultArray = content.data
      const productsArray = loadFlag
        ? [...state.products, ...transformHistory(resultArray)]
        : [...transformHistory(resultArray), ...state.products]
      // удаляем дубликаты
      const productFilteredArray = productsArray.filter(
        (v, i, a) => a.findIndex((t) => t.id === v.id) === i
      )
      const productSlicedArray = loadFlag
        ? productFilteredArray.slice(-NEW_HISTORY.MAX_PRODUCT_ITEMS)
        : productFilteredArray.slice(0, NEW_HISTORY.MAX_PRODUCT_ITEMS)

      return {
        ...state,
        products: productSlicedArray,
        error: {},
        total: content.total,
        direction: loadFlag ? LoadDirections.DOWN : LoadDirections.UP,
      }
    case NewHistoryActionTypes.FETCH_PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case NewHistoryActionTypes.SET_PRODUCT_PAGE:
      return {
        ...state,
        page: action.payload,
      }

    case NewHistoryActionTypes.SET_PRODUCT_SORT:
      const order = action.payload.order
      const orderBy = action.payload.orderBy
      const newOrder = order === 'asc' ? '+' : '-'
      return {
        ...state,
        filter: { ...state.filter, order: newOrder + orderBy },
        products: [],
        page: 1,
        enough: false,
        startSearch: !state.startSearch,
        orderBy,
        order,
      }

    case NewHistoryActionTypes.SET_DELETED_ITEM:
      return {
        ...state,
        deletedItem: action.payload,
      }

    case NewHistoryActionTypes.DELETE_PRODUCT_BY_ID:
      const filteredItems = state.products.filter(
        (i) => i.id !== state.checkedItems[0]
      )
      return {
        ...state,
        products: filteredItems,
      }

    case NewHistoryActionTypes.UPDATE_PRODUCT_BY_ID:
      const index = state.products.findIndex(
        (i) => i.id === state.checkedItems[0]
      )
      const newArray = [...state.products]
      newArray[index].statusId = HISTORY_STATUS.UPLOADED
      return {
        ...state,
        products: newArray,
      }

    case NewHistoryActionTypes.FETCH_PRODUCTS_ENOUGH:
      return {
        ...state,
        enough: action.payload,
      }

    case NewHistoryActionTypes.SET_IS_CHECKED_ALL:
      return {
        ...state,
        isCheckedAll: !state.isCheckedAll,
      }

    case NewHistoryActionTypes.SHOW_IS_NOTED_ITEMS:
      return {
        ...state,
        isShowNoted: action.payload,
      }

    case NewHistoryActionTypes.SHOW_IS_ERRORS_IN_ITEMS:
      return {
        ...state,
        isShowErrors: action.payload,
      }

    case NewHistoryActionTypes.SET_CHECKED_ITEMS:
      return {
        ...state,
        checkedItems: action.payload,
      }

    case NewHistoryActionTypes.ADD_CHECKED_ITEMS:
      return {
        ...state,
        checkedItems: [...state.checkedItems, ...action.payload],
      }

    case NewHistoryActionTypes.SAVE_ORDER_ID:
      return {
        ...state,
        orderId: action.payload,
      }
    default:
      return state
  }
}
