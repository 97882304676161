/// <reference types="vite-plugin-svgr/client" />
import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'
import PercentageIcon from '@images/Percentage.svg?react'
import { Tooltip } from '@mui/material'

const SvgPercentage = ({
  renderCondition = false,
}: {
  renderCondition?: boolean
  percent?: number
}) => {
  if (!renderCondition) return null
  return (
    <Tooltip title="акция">
      <SvgIcon
        viewBox="0 0 20 20"
        style={{
          width: '20px',
          height: '20px',
        }}
      >
        <PercentageIcon />
      </SvgIcon>
    </Tooltip>
  )
}

export default SvgPercentage
