/// <reference types="vite-plugin-svgr/client" />
import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'
import CircleMIcon from '@images/Circled M.svg?react'
import { Tooltip } from '@mui/material'

const SvgCircleM = ({
  renderCondition = false,
}: {
  renderCondition?: boolean
}) => {
  if (!renderCondition) return null
  return (
    <Tooltip title="маркированный товар">
      <SvgIcon
        viewBox="0 0 20 20"
        style={{
          width: '20px',
          height: '20px',
        }}
      >
        <CircleMIcon />
      </SvgIcon>
    </Tooltip>
  )
}

export default SvgCircleM
