import React from 'react'
import Box from '@mui/material/Box'
import { useHistory } from 'react-router-dom'
import { getHomeRoute } from '../../../../shared/services/routeService'
import logo from '@images/only_logo_wht.png'
import { DRAWER } from '../../../../shared/consts/drawer'
import { IconButton, useMediaQuery, useTheme } from '@mui/material'

const LogoAndName = ({
  handleDrawerToggle,
}: {
  handleDrawerToggle?: () => void
}) => {
  const history = useHistory()
  const theme = useTheme()
  const matchDownLg = useMediaQuery(theme.breakpoints.down('lg'))
  return (
    <Box
      sx={{
        display: 'grid',
        placeContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          columnGap: '10px',
          width: { xs: 'none', lg: `calc(${DRAWER.WIDTH}px - 1rem)` },
        }}
      >
        {matchDownLg ? (
          <IconButton
            onClick={handleDrawerToggle}
            sx={{
              '&:hover': {
                backgroundColor: 'neutral.main',
                cursor: 'pointer',
              },
            }}
          >
            <img src={logo} alt="logo" height="35px" />
          </IconButton>
        ) : (
          <img src={logo} alt="logo" height="35px" />
        )}

        {!matchDownLg && (
          <Box
            onClick={() => history.push(getHomeRoute())}
            sx={{
              fontWeight: '700',
              fontSize: '24px',
              position: 'relative',
              paddingX: 1,

              '&::before': {
                content: '" "',
                display: 'block',
                position: 'absolute',
                top: 200,
                right: 0,
                bottom: 0,
                left: 0,
                inset: '0 0 0 0',
                backgroundColor: 'neutral.main',
                zIndex: -1,
                transition: 'transform .3s ease',
                transform: 'scaleX(0)',
                transformOrigin: 'bottom right',
              },

              '&:hover::before': {
                transform: 'scaleX(1)',
                transformOrigin: 'bottom left',
              },

              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            ПРОМАРКЕТ
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default LogoAndName
