import React from 'react'

// mui imports
import Checkbox from '@mui/material/Checkbox'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'

// mui assets
import SendIcon from '@mui/icons-material/Send'
import VisibilityIcon from '@mui/icons-material/Visibility'
import EditIcon from '@mui/icons-material/Edit'
import RepeatIcon from '@mui/icons-material/Repeat'
import DeleteIcon from '@mui/icons-material/Delete'
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded'

// project imports
import FormattedDate1 from '../../../shared/components/FormattedDate1'
import FormattedPrice1 from '../../../shared/components/FormattedPrice1'
import StatusInfo from '../UI/StatusInfo'

// third libs
import { useHistory } from 'react-router-dom'
import cloneDeep from 'lodash/cloneDeep'

//service
import {
  getEditHistoryRouteByOrderId,
  getViewHistoryRouteByOrderId,
} from '../../../shared/services/routeService'

// types
import { IOrderFiltered1 } from '../types/newHistoryTypes'

// const's
import { HISTORY_STATUS } from '../consts/newHistory'
import { TYPE_ID } from '../consts/newHistory'

// actions
import { useActions } from '../../../shared/hooks/useActions'

// styled
import { RedTooltip } from '../../../shared/components/styled/RedToolTip'

// selectors
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'

// project imports
import StatusTpr from '../UI/StatusTpr'

export function HistoryRow({ row }: { row: IOrderFiltered1 }) {
  const history = useHistory()
  const {
    selectCopyOrder,
    handleNewHistoryCheckBox,
    showNewHistoryErrorModal,
    updateNewHistoryRequest,
    setNewHistoryCheckedItems,
    showNewHistoryDeleteModal,
    handleNewsHistoryDelete,
    exportOrderDetails,
  } = useActions()

  const checkedItems = useTypedSelector(
    (state) => state.newHistory.checkedItems
  )
  const products = useTypedSelector((state) => state.newHistory.products)

  const sendOrderById = (id: number) => {
    const tempArray = cloneDeep(products)
    const findObj = tempArray.find((item) => item.id === id)
    if (!findObj) return
    setNewHistoryCheckedItems([findObj.id])
    if (findObj.hasErrors.length !== 0) {
      showNewHistoryErrorModal()
      return
    }
    updateNewHistoryRequest()
  }

  const handleDeleteOrderById = (id: number) => {
    const tempArray = cloneDeep(products)
    const findObj = tempArray.find((item) => item.id === id)
    if (!findObj) return
    handleNewsHistoryDelete(id)
    showNewHistoryDeleteModal()
  }

  const handleEdit = () => {
    history.push(getViewHistoryRouteByOrderId(row.id))
  }

  const mouseDownHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (event.button === 1) {
      window.open(getViewHistoryRouteByOrderId(row.id), '_blank')
    }
  }

  const rowHasErrors = row.hasErrors.length !== 0
  const textErrors = row.hasErrors.join(',\n')
  const isTpr =
    row.typeId === TYPE_ID.COMPLEX_ORDER && row.statusId === HISTORY_STATUS.NEW
  const isNotEditable = row.statusId !== HISTORY_STATUS.NEW
  const isSent =
    row.statusId === HISTORY_STATUS.UPLOADED ||
    row.statusId === HISTORY_STATUS.SENT ||
    row.statusId === HISTORY_STATUS.PARTIALLY_AGREED ||
    row.statusId === HISTORY_STATUS.FULL_AGREED
  const isCancelled = row.statusId === HISTORY_STATUS.CANCELED
  const cancelledStatus = isCancelled ? 'rgba(250, 33, 3, 0.2)' : 'unset'

  const claims = useTypedSelector((state) => state.auth.claims)
  return (
    <TableRow
      sx={{
        backgroundColor: cancelledStatus,
        '& td': {
          fontSize: '0.875rem',
        },
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell align="left">
        <Checkbox
          disabled={isNotEditable}
          id={String(row.id)}
          onChange={handleNewHistoryCheckBox}
          checked={
            checkedItems.includes(row.id) &&
            row.statusId !== HISTORY_STATUS.UPLOADED
          }
        />
      </TableCell>
      <TableCell
        align="left"
        sx={{
          color: rowHasErrors ? '#d50505' : 'unset',
          fontWeight: '600',
        }}
      >
        {rowHasErrors ? (
          <RedTooltip
            title={<span style={{ whiteSpace: 'pre-line' }}>{textErrors}</span>}
          >
            <span style={{ cursor: 'help' }}>{row.id}</span>
          </RedTooltip>
        ) : (
          <span>{row.id}</span>
        )}
      </TableCell>

      <TableCell align="center">
        <FormattedDate1 date={row.createdAt} />
      </TableCell>

      <TableCell align="center" sx={{ minWidth: '160px' }}>
        {row.supplier}
      </TableCell>
      <TableCell align="center" sx={{ minWidth: '160px' }}>
        {row.department}
      </TableCell>
      <TableCell align="center">{row.userName}</TableCell>
      <TableCell align="center">{row.priceListName}</TableCell>
      <TableCell align="right">
        <FormattedPrice1 price={row.totalSum} />
      </TableCell>
      <TableCell align="center">{row.totalCount}</TableCell>
      <TableCell align="center">
        <StatusTpr isTpr={isTpr}>
          <StatusInfo status={row.statusId} />
        </StatusTpr>
      </TableCell>
      <TableCell>
        <Stack direction="row">
          <Tooltip title={'Просмотреть заказ'}>
            <span>
              <IconButton
                size="small"
                onClick={handleEdit}
                onMouseDown={mouseDownHandler}
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip
            title={
              isNotEditable ? 'Недоступное действие' : 'Редактировать заказ'
            }
          >
            <span>
              <IconButton
                disabled={
                  !claims['promarket.order.update'] && isTpr
                    ? true
                    : isNotEditable
                }
                size="small"
                onClick={() =>
                  history.push(getEditHistoryRouteByOrderId(row.id))
                }
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip title={'Повтор заказа'}>
            <span>
              <IconButton
                size="small"
                disabled={isTpr ? true : isNotEditable && !isSent}
                onClick={() => selectCopyOrder(row.id)}
              >
                <RepeatIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip
            title={isNotEditable ? 'Недоступное действие' : 'Удалить заказ'}
          >
            <span>
              <IconButton
                disabled={
                  !claims['promarket.order.delete'] && isTpr
                    ? false
                    : isNotEditable
                }
                onClick={() => handleDeleteOrderById(row.id)}
                size="small"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip title="Выгрузить в xlsx">
            <span>
              <IconButton
                size="small"
                onClick={() => exportOrderDetails(row.id)}
              >
                <FileDownloadRoundedIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip
            title={isNotEditable ? 'Недоступное действие' : 'Отправить заказ'}
          >
            <span>
              <IconButton
                disabled={isTpr ? false : isNotEditable}
                onClick={() => sendOrderById(row.id)}
                size="small"
              >
                <SendIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  )
}
