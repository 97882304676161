import React from 'react'

// types
import { IProductData } from '../../types/productTypes'

// controllers
import { useBuyController } from '../../../../shared/controllers/useBuyController'

// mui imports
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

// project imports
import EsCodeField from '../../UI/MainTableFields/EsCodeField'
import NameField from '../../UI/MainTableFields/NameField'
import ProducerNameField from '../../UI/MainTableFields/ProducerNameField'
import SupplierNameField from '../../UI/MainTableFields/SupplierNameField'
import PriceListField from '../../UI/MainTableFields/PriceListField'
import BoxQuantityField from '../../UI/MainTableFields/BoxQuantityField'
import MinQuantityField from '../../UI/MainTableFields/MinQuantityField'
import QuantityField from '../../UI/MainTableFields/QuantityField'
import ExpDateField from '../../UI/MainTableFields/ExpDateField'
import PriceField from '../../UI/MainTableFields/PriceField'
import AmountField from '../../UI/MainTableFields/AmountField'
import IconsFields from '../../UI/MainTableFields/IconsField'

// assets
import SvgHospital from '../../../../shared/components/UI/svgIcons/SvgHospital'
import SvgMolecule from '../../../../shared/components/UI/svgIcons/SvgMolecule'
import SvgBad from '../../../../shared/components/UI/svgIcons/SvgBad'
import SvgAccounting from '../../../../shared/components/UI/svgIcons/SvgAccounting'
import SvgDocument from '../../../../shared/components/UI/svgIcons/SvgDocument'
import SvgCheck from '../../../../shared/components/UI/svgIcons/SvgCheck'
import SvgBlueLogo from '../../../../shared/components/UI/svgIcons/SvgBlueLogo'
import SvgOrangeLogo from '../../../../shared/components/UI/svgIcons/SvgOrangeLogo'
import SvgCircleM from '../../../../shared/components/UI/svgIcons/SvgCircleM'
import SvgCircleU from '../../../../shared/components/UI/svgIcons/SvgCircleU'
import SvgClock from '../../../../shared/components/UI/svgIcons/SvgClock'
import SvgPercentage from '../../../../shared/components/UI/svgIcons/SvgPercentage'
import SvgGreenLogo from '../../../../shared/components/UI/svgIcons/SvgGreenLogo'
import { getCardPrimaryKey } from '../../../../shared/services/commonService'
import SvgHealthIcon from '../../../../shared/components/UI/svgIcons/SvgHealth'
import SvgRedLogo from '../../../../shared/components/UI/svgIcons/SvgRedLogo'
import { useTypedSelector } from '../../../../shared/hooks/useTypedSelector'

interface IProductWideCardRow {
  card: IProductData
  expanded: boolean
  handleExpandClick: () => void
  amountFieldRef: React.RefObject<HTMLInputElement>
}

const ProductWideCardRow = ({
  card,
  handleExpandClick,
  expanded,
  amountFieldRef,
}: IProductWideCardRow) => {
  const { product, info } = card.productWithInfo
  const claims = useTypedSelector((state) => state.auth.claims)

  const { changeItemAmount, defaultDepartment, itemAmount } =
    useBuyController(product)

  const {
    isBad,
    isBenefit,
    isImmunoBiological,
    isJvnls,
    isOa,
    isPrPkkn,
    isReceipt,
    remainExpirationDays,
  } = product || {}

  const {
    isInProAptMm,
    isInMatrixMm,
    isInHighMarginProductMm,
    isInFirstTableBonusMm,
    proAptMarketingEvents,
    matrixMarketingEvents,
    highMarginProductMarketingEvents,
    firstTableBonusMarketingEvents,
  } = info.marketingEvents

  const healthPm = info.compensatedDiscounts

  const primaryKey = getCardPrimaryKey(product, defaultDepartment?.id)

  const isExpiredDay = remainExpirationDays < 181

  return (
    <>
      <Grid item xs={'auto'} minWidth="40px">
        <Box marginTop="20px">
          <Box display="flex">
            <SvgAccounting renderCondition={isPrPkkn} />
            <SvgDocument renderCondition={isReceipt} />
            <SvgCheck renderCondition={isOa} />
            <SvgClock
              days={remainExpirationDays}
              renderCondition={isExpiredDay}
            />
          </Box>
          <Box display="flex">
            <SvgHospital renderCondition={isJvnls} />
            <SvgMolecule renderCondition={isImmunoBiological} />
            <SvgBad renderCondition={isBad} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={'auto'} minWidth="40px">
        <EsCodeField card={product} />
      </Grid>
      <Grid item xs={2}>
        <NameField card={product} />
      </Grid>

      <Grid item xs={0.6}>
        <Box marginTop="20px">
          <Box display="flex" flexWrap={'wrap'}>
            <SvgBlueLogo
              events={proAptMarketingEvents}
              renderCondition={isInProAptMm}
            />
            <SvgOrangeLogo
              events={firstTableBonusMarketingEvents}
              renderCondition={isInFirstTableBonusMm}
            />
          </Box>
          <Box display="flex">
            <SvgRedLogo
              events={highMarginProductMarketingEvents}
              renderCondition={isInHighMarginProductMm}
            />
            <SvgGreenLogo
              events={matrixMarketingEvents}
              renderCondition={isInMatrixMm}
            />
            <SvgHealthIcon
              health={healthPm}
              renderCondition={!!healthPm.length}
            />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={1}>
        <ProducerNameField card={product} />
      </Grid>
      <Grid item xs={1}>
        <SupplierNameField card={product} />
      </Grid>
      <Grid item xs={1}>
        <PriceListField card={product} />
      </Grid>
      <Grid item xs={'auto'}>
        <Box marginTop="20px">
          <Box display="flex">
            <SvgCircleM renderCondition={false} />
            <SvgCircleU renderCondition={false} />
          </Box>
          <Box display="flex">
            <SvgPercentage renderCondition={false} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={0.5}>
        <BoxQuantityField card={product} itemAmount={itemAmount} />
      </Grid>
      <Grid item xs={1}>
        <MinQuantityField card={product} itemAmount={itemAmount} />
      </Grid>
      <Grid item xs={0.5}>
        <QuantityField card={product} itemAmount={itemAmount} />
      </Grid>
      <Grid item xs={1}>
        <ExpDateField card={product} />
      </Grid>
      <Grid item xs={1}>
        <PriceField card={product} />
      </Grid>
      {claims['promarket.basket_item.upsert'] && defaultDepartment && (
        <Grid item xs={1}>
          <AmountField
            basketInfo={info}
            amountFieldRef={amountFieldRef}
            changeItemAmount={changeItemAmount}
            primaryKey={primaryKey}
          />
        </Grid>
      )}
      <Grid item xs={1.3}>
        <IconsFields
          card={product}
          handleExpandClick={handleExpandClick}
          expanded={expanded}
        />
      </Grid>
    </>
  )
}

export default ProductWideCardRow
