export interface NotificationsState {
  notification: any
}

export enum NotificationsActionTypes {
  SET_NOTIFICATION = 'SET_NOTIFICATION',
}

export interface AddEventSourceListener {
  type: NotificationsActionTypes.SET_NOTIFICATION
  payload: any
}

export type NotificationsAction = AddEventSourceListener
