// ui
import { Card, CardContent, Grid, Box, Button, CardMedia } from '@mui/material'

// assets
import SettingsIcon from '@mui/icons-material/Settings'

// third-party
import { FallbackProps } from 'react-error-boundary'

// styles
import {
  ErrorImageText,
  ErrorInfoText,
} from '../shared/components/styled/ErrorFallbackStyled'

//assets
import img1 from '@images/plush-toy-file_400x400.png'

const ErrorFallbackPage = ({ error, resetErrorBoundary }: FallbackProps) => {
  const onClickReset = () => {
    resetErrorBoundary()
    localStorage.removeItem('persist:root')
    window.location.reload()
  }

  return (
    <Card
      elevation={0}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              sx={{
                maxWidth: '720px',
                margin: '0px auto',
                position: 'relative',
              }}
            >
              <CardMedia
                component="img"
                src={img1}
                alt=""
                sx={{
                  width: 'auto',
                  maxHeight: '250px',
                  margin: '0 auto',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                maxWidth: '350px',
                margin: ' 0px auto',
                textAlign: 'center',
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ErrorImageText>Ой, ошибка...</ErrorImageText>
                </Grid>
                <Grid item xs={12}>
                  <ErrorInfoText>
                    <pre style={{ whiteSpace: 'break-spaces' }}>
                      {error.message}
                    </pre>
                  </ErrorInfoText>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={onClickReset}
                    startIcon={<SettingsIcon />}
                  >
                    Сбросить
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ErrorFallbackPage
