export const TRANSLATIONS_RU = {
  dialog: {
    none: 'Нет опций',
    new: 'Новый заказ',
    completed: 'В очереди на отправку',
    sended: 'Отправлен',
    parted_approved: 'Частично одобрен',
    approved: 'Одобрен',
    refused: 'Отменен',
  },
}
