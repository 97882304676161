import { useEffect } from 'react'
import { useTypedSelector } from './useTypedSelector'
import { useActions } from './useActions'
import { authController } from '../api/config/instance'
import { SnackTypes } from '../store/application/applicationReduxTypes'

export const useExportExcel = () => {
  let urlArr: string[] = []
  const notification = useTypedSelector(
    (state) => state.notifications.notification
  )
  const { showSnack } = useActions()

  useEffect(() => {
    notification.forEach(async (item: any) => {
      if (!urlArr.includes(item.downloadUrl)) {
        try {
          urlArr.push(item.downloadUrl)

          await authController.authorizeService(
            `${import.meta.env.VITE_APP_REPORT_CREATOR}api`
          )

          showSnack({
            typeSnack: SnackTypes.SUCCESS,
            messageSnack: 'Файл сформирован!',
            snackForDownload: true,
            handleSnackClick: () => fetchData(item.downloadUrl),
          })
          urlArr.shift()
        } catch (e) {
          showSnack({
            typeSnack: SnackTypes.ERROR,
            messageSnack:
              'Ошибка при выгрузке данных. Попробуйте ещё раз или обратитесь к администрации.',
          })
        }
      }
    })
  }, [notification])

  const fetchData = async (url: any) => {
    let link = document.createElement('a')
    link.download = 'report.xlsx'
    link.href = url
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
