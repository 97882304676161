import React from 'react'
import { useActions } from '../../../../shared/hooks/useActions'
import { Backdrop, Box, Fade, Modal } from '@mui/material'
import { useTypedSelector } from '../../../../shared/hooks/useTypedSelector'
import ExportOrderDetailsContent from './ExportOrderDetailsContent'
import ModalCloseButton from '../../../../shared/components/UI/ModalCloseButton'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 410,
  height: 230,
  bgcolor: 'background.paper',
  border: '2px solid',
  borderColor: 'action.disabled',
  boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  padding: '3.5rem',
}

const ExportOrderDetailsModal = () => {
  const { active } = useTypedSelector((state) => state.modal)
  const { modalDisable } = useActions()

  const handleClose = () => {
    modalDisable()
  }

  return (
    <>
      <Modal
        open={active}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={active}>
          <Box sx={style}>
            <ExportOrderDetailsContent handleClose={handleClose} />
            <ModalCloseButton handleClose={handleClose} />
          </Box>
        </Fade>
      </Modal>
    </>
  )
}

export default ExportOrderDetailsModal
