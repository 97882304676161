import http from './config/instance'
import { IInitialProduct } from '../../features/Basket/types/basketTypes'

export const postApproveOrders = (params: number[]) =>
  http.httpCore.post('order-approve', {
    orderIdRange: params,
  })

export const postAddItemOrders = (
  amount: number,
  productId: string | number,
  orderId: number,
  initialProduct: IInitialProduct
) =>
  http.httpCore.post('order/add-item', {
    amount,
    productId,
    orderId,
    initialProduct,
  })

export const postCopyOrderId = (orderId: number, departments: number[]) =>
  http.httpCore.post('order-copy', {
    orderIdRange: [orderId],
    departmentIdRange: departments,
  })
