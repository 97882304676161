/// <reference types="vite-plugin-svgr/client" />
import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'
import GreenLogoIcon from '@images/only_logo_green.svg?react'
import { Tooltip } from '@mui/material'
import { MarketingEvent } from '../../../../features/Product/types/productTypes'

const SvgGreenLogo = ({
  renderCondition = false,
  events,
  initialSizes = {
    width: '20px',
    height: '20px',
  },
}: {
  renderCondition?: boolean
  events?: MarketingEvent[]
  initialSizes?: {
    [key: string]: string
  }
}) => {
  const eventsList = events?.map((event) => (
    <div key={event.eventId}>{event.eventName}</div>
  ))

  if (!renderCondition) return null

  return (
    <Tooltip
      title={eventsList || ''}
      sx={{
        '& .MuiTooltip-tooltip': {
          padding: '4px',
        },
      }}
    >
      <SvgIcon
        viewBox="0 0 20 20"
        style={{
          ...initialSizes,
        }}
      >
        <GreenLogoIcon />
      </SvgIcon>
    </Tooltip>
  )
}

export default SvgGreenLogo
