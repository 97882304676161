/// <reference types="vite-plugin-svgr/client" />
import SvgIcon from '@mui/material/SvgIcon'
import AlertIcon from '@images/alert_info.svg?react'
import { Tooltip } from '@mui/material'

const SvgAlert = () => {
  return (
    <Tooltip
      open={true}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'rgba(255, 233, 233, 0.8)',
            color: '#EA0000',
            fontSize: '10px',
          },
        },
      }}
      placement="bottom-end"
      title="Введите значение больше"
    >
      <SvgIcon
        viewBox="0 0 15 15"
        style={{
          width: '15px',
          height: '15px',
        }}
      >
        <AlertIcon />
      </SvgIcon>
    </Tooltip>
  )
}

export default SvgAlert
