import React, { useState } from 'react'
import { Button, Stack, Typography } from '@mui/material'
import { CloseModal, IOptionsProps } from '../../../../shared/types/types'
import { useTypedSelector } from '../../../../shared/hooks/useTypedSelector'
import Box from '@mui/material/Box'
import { getEditHistoryRouteByOrderId } from '../../../../shared/services/routeService'
import { useHistory } from 'react-router-dom'
import { postCopyOrderId } from '../../../../shared/api/order'
import DepartentAutoComplete from '../../../../shared/components/autoCompletes/DepartmentAutoComplete'

const CopyOrderContent = ({ handleClose }: CloseModal) => {
  const { orderId } = useTypedSelector((state) => state.newHistory)
  const history = useHistory()
  const [departments, setDepartments] = useState<number[]>()

  const handleDepartment = (newValue: IOptionsProps[] | null) => {
    if (newValue) setDepartments(newValue.map((v) => v.id))
  }
  const handleSubmit = () => {
    if (orderId && departments)
      postCopyOrderId(orderId, departments)
        .then((res) => {
          const id = res.data.content.orders[0].id
          history.push(getEditHistoryRouteByOrderId(id))
        })
        .finally(() => handleClose())
  }

  return (
    <>
      <Typography
        sx={{
          color: 'secondary.main',
          fontSize: '1.5625rem',
          textAlign: 'center',
          fontWeight: '600',
        }}
      >
        Для редактирования заказа выберите грузополучателей
      </Typography>
      <Box sx={{ my: '2.75rem' }}>
        <DepartentAutoComplete
          variant={'multiple'}
          handleAutoCompleteFields={handleDepartment}
          isCheckBoxType={false}
        />
      </Box>
      <Stack flexDirection="row" alignItems="center" columnGap={'1rem'}>
        <Button onClick={handleSubmit} variant="contained" fullWidth>
          Подтвердить
        </Button>

        <Button onClick={handleClose} variant="outlined" fullWidth>
          Отменить
        </Button>
      </Stack>
    </>
  )
}

export default CopyOrderContent
