import React, { useMemo } from 'react'

// project imports
import ErrorPage from '../Product/components/ErrorPage'
import ElementWatch from '../Product/components/ElementWatch'
import HistoryAppBar from './components/HistoryAppBar'
import { IOrderFiltered1, NewHistoryFieldType } from './types/newHistoryTypes'

// controllers
import { useNewHistoryController } from './controllers/useNewHistoryController'
import { useExportExcel } from '../../shared/hooks/useExportExel'

//actions
import { useActions } from '../../shared/hooks/useActions'

// selectors
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'

// const
import { headCellsArray } from './consts/newHistory'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import TableSortLabel from '@mui/material/TableSortLabel'
import TableBody from '@mui/material/TableBody'
import { HistoryRow } from './components/HistoryRow'
import TableContainer from '@mui/material/TableContainer'

const NewHistoryMain = () => {
  const {
    error,
    firstElement,
    lastElement,
    inputRef,
    loading,
    products,
    order,
    orderBy,
  } = useNewHistoryController()

  const { newHistorySelectAllItems, setNewHistorySort } = useActions()

  const { isCheckedAll, checkedItems, isShowNoted } = useTypedSelector(
    (state) => state.newHistory
  )

  const sortHandler = (field: NewHistoryFieldType) => {
    const isAsc = orderBy === field && order === 'asc'
    setNewHistorySort({ orderBy: field, order: isAsc ? 'desc' : 'asc' })
  }

  if (Object.keys(error).length > 0) {
    return (
      <ErrorPage
        {...{
          error,
          firstElement,
          lastElement,
        }}
      />
    )
  }

  useExportExcel()
  return (
    <>
      <ElementWatch element={firstElement} isLoading={loading} />
      <HistoryAppBar />
      <TableContainer
        sx={{
          border: '1px solid',
          borderColor: 'action.disabled',
          boxShadow: '0px 8px 24px -4px rgba(27, 46, 94, 0.08)',
          borderRadius: '8px',
          pb: 2,
          overflowX: 'initial',
        }}
      >
        <Table size="small" aria-label="a alternative-product table">
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={13}
                sx={{
                  borderRadius: '8px',
                  backgroundColor: 'background.paper',
                  position: 'sticky',
                  top: 65,
                  zIndex: 1,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ ml: 1 }}
                      onChange={newHistorySelectAllItems}
                      checked={isCheckedAll}
                    />
                  }
                  label={
                    <Typography
                      fontSize="14px"
                      fontWeight="400"
                      color="#5F5F5F"
                    >
                      Выделить все
                    </Typography>
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                '& th': {
                  whiteSpace: 'nowrap',
                  fontSize: '0.75rem',
                  color: 'primary.main',
                  fontWeight: '700',
                  textTransform: 'uppercase',
                  borderBottom: 0,
                  backgroundColor: 'background.paper',
                  position: 'sticky',
                  top: 110,
                  zIndex: 1,
                },
              }}
            >
              <TableCell />
              {headCellsArray.map((item: any) => (
                <TableCell
                  key={item.name}
                  align={item.align}
                  sx={{
                    padding: item.enablePaddings
                      ? '0.5rem 1rem 0 1rem'
                      : '0.5rem 0 0 0',
                  }}
                >
                  {item.disableSort ? (
                    <Box sx={{ pl: item.enableLeftPadding ? '18px' : 'unset' }}>
                      {item.name}
                    </Box>
                  ) : (
                    !item.disableField && (
                      <TableSortLabel
                        active={orderBy === item.field}
                        direction={orderBy === item.field ? order : 'asc'}
                        onClick={() => sortHandler(item.field)}
                      >
                        <Box
                          sx={{ pl: item.enableLeftPadding ? '18px' : 'unset' }}
                        >
                          {item.name}
                        </Box>
                      </TableSortLabel>
                    )
                  )}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {products.map((row) => (
              <React.Fragment key={row.id}>
                <HistoryRow row={row} />
                <tr
                  // @ts-ignore
                  ref={(el) => (inputRef.current[row.id] = el)}
                />
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ElementWatch element={lastElement} isLoading={loading} />
    </>
  )
}

export default NewHistoryMain
