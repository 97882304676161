import { IOptionsProps } from '../../types/types'
import { IUserSettings } from '../../types/userTypes'

export interface ISideBarFilter {
  [key: string]: any

  producerCountryFilter: boolean
  expirationDateFilter: boolean
  gtinFilter: boolean
  boxQuantityFilterMin: boolean
  producerNameFilter: boolean
  producerPriceFilterMin: boolean
  minimalQuantityFilterMin: boolean
  internationalNameFilter: boolean
  vatRateFilter: boolean
  remainExpirationPercentFilter: boolean
  priceFilter: boolean
  registerPriceFilterMin: boolean
  nameFilter: boolean
  supplierIdFilter: boolean
  packQuantityFilterMin: boolean
  esCodeFilter: boolean
  wholesaleMarkupFilterMin: boolean
  marketingEventTypesFilter: boolean
}

export interface ISnackBar {
  openSnack: boolean
  typeSnack: SnackTypes.SUCCESS | SnackTypes.WARNING | SnackTypes.ERROR
  messageSnack: string | undefined
  messageSnackErrorInfo: string | undefined
  messageSnackErrorGuide: string | undefined
  messageSnackErrorCode: string | undefined
  snackForDownload: boolean | undefined
  handleSnackClick: any
}

export enum SnackTypes {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum ModeList {
  UNSET = 'UNSET',
  EDIT = 'EDIT',
}

export interface IAppMode {
  mode: ModeList.UNSET | ModeList.EDIT
  data: string | number
}

export enum ApplicationActionTypes {
  SEARCH_QUERY = 'SEARCH_QUERY',
  SAVE_FILTER = 'SAVE_FILTER',
  SAVE_FILTER_VALUES = 'SAVE_FILTER_VALUES',
  SAVE_SCROLL_POSITION = 'SAVE_SCROLL_POSITION',
  LOADING_ENABLE = 'LOADING_ENABLE',
  LOADING_DISABLE = 'LOADING_DISABLE',
  SHOW_SNACK = 'SHOW_SNACK',
  HIDE_SNACK = 'HIDE_SNACK',
  SAVE_DEFAULT_DEPARTMENT = 'SAVE_DEFAULT_DEPARTMENT',
  SAVE_DEFAULT_USER = 'SAVE_DEFAULT_USER',
  EDIT_ITEMS_MODE_ENABLE = 'EDIT_ITEMS_MODE_ENABLE',
}

export interface IApplicationState {
  defaultDepartment: IOptionsProps | null
  loading: boolean
  snack: ISnackBar | any //todo типизация
  scrollPosition: number
  searchQuery: string
  sideBarFilter: ISideBarFilter
  appMode: IAppMode
  filterValues: Object
  user: IUserSettings
}

export interface SearchQueryAction {
  type: ApplicationActionTypes.SEARCH_QUERY
  payload: string
}

export interface SaveScrollPositionAction {
  type: ApplicationActionTypes.SAVE_SCROLL_POSITION
  payload: number
}

export interface SaveFilterAction {
  type: ApplicationActionTypes.SAVE_FILTER
  payload: ISideBarFilter
}

export interface SaveFilterValuesAction {
  type: ApplicationActionTypes.SAVE_FILTER_VALUES
  payload: Object
}

export interface SaveDefaultDepartmentAction {
  type: ApplicationActionTypes.SAVE_DEFAULT_DEPARTMENT
  payload: IOptionsProps | null
}

export interface SaveDefaultUserAction {
  type: ApplicationActionTypes.SAVE_DEFAULT_USER
  payload: IUserSettings
}

export interface LoadingEnableAction {
  type: ApplicationActionTypes.LOADING_ENABLE
}

export interface LoadingDisableAction {
  type: ApplicationActionTypes.LOADING_DISABLE
}

export interface ShowSnackAction {
  type: ApplicationActionTypes.SHOW_SNACK
  payload: {
    typeSnack: SnackTypes.SUCCESS | SnackTypes.WARNING | SnackTypes.ERROR
    messageSnack?: string
    messageSnackErrorInfo?: string
    messageSnackErrorGuide?: string
    messageSnackErrorCode?: string
  }
}

export interface HideSnackAction {
  type: ApplicationActionTypes.HIDE_SNACK
}

export interface EditItemsModeEnableAction {
  type: ApplicationActionTypes.EDIT_ITEMS_MODE_ENABLE
  payload: IAppMode
}

export type ApplicationAction =
  | SearchQueryAction
  | SaveFilterAction
  | SaveScrollPositionAction
  | LoadingEnableAction
  | LoadingDisableAction
  | ShowSnackAction
  | HideSnackAction
  | SaveDefaultDepartmentAction
  | EditItemsModeEnableAction
  | SaveFilterValuesAction
  | SaveDefaultUserAction
