import React, { useState } from 'react'
import {
  Button,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import Box from '@mui/material/Box'
import CustomDateRangePicker from '../../../../shared/components/UI/CustomRangePicker/CustomRangePicker'
import {
  CloseModal,
  IDateRange,
  IOptionsProps,
} from '../../../../shared/types/types'
import DepartmentAutoComplete from '../../../../shared/components/autoCompletes/DepartmentAutoComplete'
import SupplierAutoComplete from '../../../../shared/components/autoCompletes/SupplierAutoComplete'
import StatusAutoComplete from '../../../../shared/components/autoCompletes/StatusAutoComplete'
import { IOrderStatusProps } from '../../types/newHistoryTypes'
import { useExportController } from '../../controllers/useExportController'

const style = { width: 446, flexShrink: 0 }

const ExportOrdersInfoContent = ({ handleClose }: CloseModal) => {
  const [dateRangeValue, setDateRangeValue] = useState<IDateRange>({
    startDate: null,
    endDate: null,
  })
  const [departments, setDepartments] = useState<number[]>()
  const [suppliers, setSuppliers] = useState<number[]>()
  const [statusValues, setStatusValues] = useState<IOrderStatusProps[] | null>()
  const [isChecked, setIsChecked] = useState(true)

  const { handleExportOrdersInfo } = useExportController()

  const handleSubmit = () => {
    handleExportOrdersInfo(
      isChecked,
      statusValues || null,
      dateRangeValue || null,
      departments || null,
      suppliers || null
    )
    handleClose()
  }
  const handleDateRangeChange = (newValue: IDateRange) => {
    setDateRangeValue(newValue)
  }

  const handleDepartment = (newValue: IOptionsProps[] | null) => {
    if (newValue) {
      setDepartments(newValue.map((v) => v.id))
    }
  }
  const handleSupplier = (newValue: IOptionsProps[] | null) => {
    if (newValue) {
      setSuppliers(newValue.map((v) => v.id))
    }
  }
  const handleStatuses = (newValue: IOrderStatusProps[] | null) => {
    if (newValue) {
      setStatusValues(newValue)
    }
  }
  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked)
  }
  return (
    <>
      <Typography
        sx={{
          color: 'secondary.main',
          fontSize: '1.5625rem',
          textAlign: 'center',
          fontWeight: '600',
        }}
      >
        Выберите по каким заказам выгрузить информацию
      </Typography>
      <Stack
        flexDirection="column"
        alignItems="start"
        rowGap="1rem"
        sx={{ my: '2rem' }}
      >
        <Stack flexDirection="column" alignItems="center" rowGap={'1rem'}>
          <CustomDateRangePicker
            rangePickerValue={dateRangeValue}
            onDateRangeChange={handleDateRangeChange}
          />

          <React.Fragment>
            <Box sx={style}>
              <DepartmentAutoComplete
                variant={'multiple'}
                handleAutoCompleteFields={handleDepartment}
                isCheckBoxType={false}
              />
            </Box>
          </React.Fragment>

          <React.Fragment>
            <Box sx={style}>
              <SupplierAutoComplete
                isCheckBoxType={false}
                variant={'multiple'}
                handleAutoCompleteFields={handleSupplier}
              />
            </Box>
          </React.Fragment>

          <React.Fragment>
            <StatusAutoComplete
              variant="multiple"
              fieldWidth="446px"
              handleAutoCompleteFields={handleStatuses}
            />
          </React.Fragment>
        </Stack>

        <FormControlLabel
          control={
            <Checkbox
              name="onlyMyOrders"
              checked={isChecked}
              onChange={handleChecked}
            />
          }
          label={
            <Typography
              sx={{ fontSize: '14px', fontWeight: '400', color: '#5F5F5F' }}
            >
              Мои заказы
            </Typography>
          }
        />
      </Stack>

      <Stack flexDirection="row" alignItems="center" columnGap={'1rem'}>
        <Button onClick={handleSubmit} variant="contained" fullWidth>
          Подтвердить
        </Button>

        <Button onClick={handleClose} variant="outlined" fullWidth>
          Отменить
        </Button>
      </Stack>
    </>
  )
}

export default ExportOrdersInfoContent
