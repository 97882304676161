import { onlyNotEmpty } from '../../../shared/services/commonService'
import http from '../../../shared/api/config/instance'
import { IOrderHistory } from '../types/newHistoryTypes'

export const getOrderHistory = (
  filters: any,
  pageNumber?: number,
  pageSize?: number
) => {
  const newFilters = onlyNotEmpty(filters)
  return http.httpCore.get<{ content: IOrderHistory }>('order', {
    params: {
      sort: filters.order,
      pageSize,
      pageNumber,
      getOrderStatuses: true,
      ...newFilters,
    },
  })
}
export const deleteOrders = (params: number | null) =>
  http.httpCore.delete('order', {
    data: {
      ordersIdRange: [params],
    },
  })

export const getExportOrderDetails = (params: number | null) => {
  return http.httpCore.get(`order/${params}/report`)
}

export const getExportOrdersInfo = (
  onlyMyOrders: boolean,
  statusIds: number[] | null,
  dateFrom: string | null,
  dateTo: string | null,
  departmentIds: number[] | null,
  supplierIds: number[] | null
) => {
  return http.httpCore.get('order/report', {
    params: {
      dateFrom,
      dateTo,
      onlyMyOrders,
      departmentIds,
      supplierIds,
      statusIds,
    },
  })
}
