import {
  NotificationsAction,
  NotificationsState,
  NotificationsActionTypes,
} from './notificationsTypes'

const initialState: NotificationsState = {
  notification: [],
}
export const notificationsReducer = (
  state = initialState,
  action: NotificationsAction
): NotificationsState => {
  switch (action.type) {
    case NotificationsActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: [...state.notification, action.payload],
      }
    default:
      return state
  }
}
