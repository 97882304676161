import * as ModalActionCreators from './modal/modalAction'
import * as ApplicationActionCreators from './application/applicationAction'
import * as BasketActionCreators from '../../features/Basket/model/basket/basketAction'
import * as ProductActionCreators from '../../features/Product/model/product/productAction'
import * as AuthActionCreators from './auth/authAction'
import * as AlternativeActionCreators from '../../features/Others/model/other/otherAction'
import * as SupplierActionCreators from '../../features/Supplier/model/supplier/supplierAction'
import * as SimilarActionCreators from '../../features/Similar/model/similar/similarAction'
import * as NewHistoryActionCreators from '../../features/NewHistory/model/newHistory/newHistoryAction'
import * as SaleDetailsActionCreators from '../../features/SaleDetails/model/saleDetails/saleDetailsAction'
import * as SaleComplexActionCreators from '../../features/Sale/components/SaleComplex/model/saleComplex/saleComplexAction'
import * as SaleDepartmentsActionCreators from '../../features/Sale/components/SaleDepartments/model/saleDepartments/saleDepartmentsAction'
import * as SaleStopListActionCreators from './saleStopLists/saleStopListsAction'
import * as SaleCostBonusesActionCreators from '../../features/Sale/components/SaleCostBonuses/model/saleCostBonuses/saleCostBonusesAction'
import * as PriceListActionCreators from '../../features/Supplier/components/PriceList/model/priceList/priceListAction'
import * as AutoOrderActionCreators from '../../features/AutoOrder/model/autoOrder/autoOrderAction'
import * as SubAutoOrderActionCreators from '../../features/SubAutoOrder/model/subAutoOrder/SubAutoOrderAction'
import * as ProcessingAutoOrderActionCreators from '../../features/SettingsAutoOrder/components/ProcessingAutoOrder/model/processingAutoOrder/processingAutoOrderActions'
import * as SupplierSettingsActionCreators from '../../features/SettingsAutoOrder/components/SupplierSettings/model/supplierSettings/supplierSettingsActions'
import * as DepartmentsPreOrderActionCreators from '../../features/AutoOrderFinal/components/DepartmentsPreOrder/model/departmentsPreOrdersAction'
import * as AutoOrderProductsActionCreators from '../../features/AutoOrderFinal/components/AutoOrderProducts/model/autoOrderProductsAction'
import * as SupplierListActionCreators from '../../features/SettingsAutoOrder/components/SupplierList/model/supplierList/supplierListActions'
import * as NotificationsActionsCreator from './notifications/notificatonsAction'

const exportedCreators = {
  ...ModalActionCreators,
  ...ApplicationActionCreators,
  ...BasketActionCreators,
  ...AuthActionCreators,
  ...ProductActionCreators,
  ...AlternativeActionCreators,
  ...SupplierActionCreators,
  ...SimilarActionCreators,
  ...NewHistoryActionCreators,
  ...SaleDetailsActionCreators,
  ...SaleComplexActionCreators,
  ...SaleDepartmentsActionCreators,
  ...SaleStopListActionCreators,
  ...SaleCostBonusesActionCreators,
  ...PriceListActionCreators,
  ...AutoOrderActionCreators,
  ...SubAutoOrderActionCreators,
  ...ProcessingAutoOrderActionCreators,
  ...SupplierSettingsActionCreators,
  ...DepartmentsPreOrderActionCreators,
  ...AutoOrderProductsActionCreators,
  ...SupplierListActionCreators,
  ...NotificationsActionsCreator,
}

export default exportedCreators
